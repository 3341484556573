// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-category-page-js": () => import("./../src/templates/categoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-creators-page-js": () => import("./../src/templates/creatorsPage.js" /* webpackChunkName: "component---src-templates-creators-page-js" */),
  "component---src-templates-document-page-js": () => import("./../src/templates/documentPage.js" /* webpackChunkName: "component---src-templates-document-page-js" */),
  "component---src-templates-repository-page-js": () => import("./../src/templates/repositoryPage.js" /* webpackChunkName: "component---src-templates-repository-page-js" */),
  "component---src-templates-static-page-js": () => import("./../src/templates/staticPage.js" /* webpackChunkName: "component---src-templates-static-page-js" */)
}

